<template lang="pug">
    section.content
        div.container-fluid
            div.row
                div.col-md-6
                    div.card
                        div.card-header
                            h3.card-title {{ $t('Results.myresults') }}
                        div.card-body
                            table.table.table-bordered
                                tbody
                                    tr
                                        td {{ $t('Results.presentation') }}:
                                        td {{model.presentacion}}
                                    tr
                                        td {{ $t('Results.date') }}: 
                                        td {{model.fecha}}
                                    tr
                                        td {{ $t('Results.total_time') }}: 
                                        td {{ model.tiempo }}
                                    tr
                                        td {{ $t('Results.correct') }}: 
                                        td {{ model.correcto }}
                                    tr
                                        td {{ $t('Results.punctuation') }}: 
                                        td {{ model.puntuacion }}
                                    tr
                                        td {{ $t('Results.result') }}: 
                                        td {{ model.resultado }}
                            button.btn.btn-sm.btn-info(type="button" @click="$router.push('/student/MyExams/')") {{ $t('Results.return') }}

</template>

<script>
/* eslint-disable */
export default {
    name: 'Results',
    props: ['idPersonExam', 'idGroupDetail'],
    components: {
    },
    data() {
        return {
            model: {
                presentacion: '',
                fecha: '',
                tiempo: '',
                correcto: '',
                puntuacion: '',
                resultado: ''
            }
        }
    },
    methods: {
        async getData(){
                await axios.get(apiURL + 'Student/getResults?idPersonExam='+ this.idPersonExam + '&idGroupDetail=' + this.idGroupDetail, { headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.userToken
            } })
            .then( (response) => {
                this.model = response.data.results
            } )
            .catch( (error)  => {
                console.log("error")
            } )
        }


    },
    mounted() {
        this.checkPermisions(this.$router.currentRoute.path)
        this.getData()
    }
}
</script>
